export default {
  translation: {
    COMPANY_NAME: '长江彩世界',
    USER_ACCOUNT: '会员账号',
    BET_TYPE: '盘口类型',
    CREDIT_AMOUNT: '信用额度',
    UNSETTLED_BALANCE: '未结金额',
    TM: '特码',
    TODAY_WINNING: '今日输赢',
    TIME_TO_OPEN: '距离开奖',
    TIME_TO_CLOSE: '距离封盘',
    SECONDS: '秒',
    INSTALLMENTS: '期',
    RECENT_DRAWS: '近期开奖',
    CUSTOMIZE_CAIZHONG: '自定义彩种',
    TMA: '特码A',
    TMB: '特码B',
    QUICKBET: '快捷投注',
    LIANG_MIAN_CHANG_LONG: '两面长龙',
    CURRENTLY_MISSING: '当前遗漏',
    HOT_COLD_MISSING: '冷热遗漏',
    CUSTOMER_SERVICE: '客服',
    HOME: '大厅',
    DEPOSIT: '存款',
    TOP_UP: '充值',
    RESULT_MOBILE: '开奖',
    RESULT: '开奖结果',
    MY: '我的',
    FORGET_PASSWORD: '忘记密码',
    FORGET_PASSWORD_HINT: '请输入正确的用户名和手机号，提交后系统会发送手机验证码到您的手机！',
    ACCOUNT: '账号',
    PHONE: '手机号码',
    VERIFY_CODE: '验证码',
    GET_VERIFY_CODE: '获取验证码',
    NEXT: '下一步',
    CHANGE_PASSWORD: '修改密码',
    CHANGE_PASSWORD_HINT: '请输入正确的用户名和手机号，提交后系统会发送手机验证码到您的手机！',
    SET_NEW_PASSWORD: '设置新密码',
    CONFIRM_NEW_PASSWORD: '再次确认新密码',
    CONFIRM: '确认',
    PASSWORD_RULE: '密码由6-20位字母+数字组合',
    REGISTER: '注册',
    AFFILIATE_CODE: '邀请码',
    PASSWORD: '密码',
    CONFIRM_PASSWORD: '确认密码',
    SMS_VERIFY_CODE: '短信验证码',
    AFFILIATE_REQUIRED_HINT: '请填写邀请码',
    AFFILIATE_NOT_REQUIRED_HINT: '没有邀请码可不填',
    LOGIN: '登录',
    REMEMBER_PASSWORD: '记住密码',
    FREE_TRIAL: '试玩',
    SETTLED: '今日已结',
    UNSETTLED: '未结明细',
    INFO: '个人资讯',
    RULES: '活动规则',
    LOGOUT: '退出系统',
    THEME: '更换皮肤',
    MCHK6: '澳门六合彩',
    HK6: '香港六合彩',
    JSSCPK10: '极速飞车',
    JSHK6: '极速六合彩',
    JSSSC: '极速分分彩',
    GXK10: '广西快乐十分',
    JSK3: '极速快三',
    DXTB: '大小骰宝',
    YXXTB: '鱼虾蟹骰宝',
    LX: '连肖',
    LW: '连尾',
    LM: '连码',
    ORDER_NUMBER: '单号',
    BET_AMOUNT: '下单金额',
    WIN_AMOUNT: '可赢金额',
    LIANGMIAN: '两面',
    MORE: '更多',
    CURRENT_SELECTED: '当前已选',
    LATEST_BET: '最新注单',
    COUNTRY_DRAWING_NET: '全国开奖网',
    DRAW: '开奖',
    MODAL_INFO: '提示',
    MODAL_ERROR: '错误',
    MODAL_ACKNOWLEDGE: '知道了',
    LOGIN_ERROR_EMPTY_PASSWORD: '密码不能为空',
    LOGIN_ERROR_USERNAME_LENGTH: '帐号错误! 请输入4-12字符组合。',
    LOGIN_CHANGE_PASSWORD_REQUIRED_HEADER: '重置密码',
    LOGIN_GO_TO_CHANGE_PASSWORD_REQUIRED: '重置密码',
    LOADING: '加载中',
    LOGIN_ERROR_EMPTY_AFFCODE: '邀请码不能为空',
    REGISTER_ERROR_REQUIRED_USERNAME: '账号不能为空',
    REGISTER_ERROR_CONFIRM_PASSWORD: '确认密码不能为空',
    REGISTER_ERROR_PASSWORD_MISMATCHED: '两次输入密码不一致',
    REGISTER_ERROR_PHONE_INVALID: '手机号码不正确',
    REGISTER_ERROR_EMPTY_SMS: '短信验证码不能为空',
    REGISTER_ERROR_EMPTY_CAPTCHA: '验证码不能为空',
    QUICK_SELECTION_AMOUNT: '快选金额',
    RESET: '重置',
    PRESET: '预设',
    AMOUNT: '金额',
    GYJZH: '冠亚军组合',
    DH: '1~10号车',
    DH15: '单号1~5',
    D1Q: '第一球',
    D2Q: '第二球',
    D3Q: '第三球',
    D4Q: '第四球',
    D5Q: '第五球',
    TOP_UP_AMOUNT: '存款金额',
    TOP_UP_INTEREST_WARN_FRONT: '本次存款将收取',
    TOP_UP_INTEREST_WARN_BACK: '手续费',
    TOP_UP_CUSTOMER_HELP_FRONT: '存款遇到问题？联系',
    IN_PERSON_SERVICE: '人工客服',
    TOP_UP_CUSTOMER_HELP_BACK: '解决',
    WITHDRAWAL: '提现',
    WITHDRAWAL_EXPLAINATION: '提现说明',
    WITHDRAWAL_PENDING_AMOUNT: '提取审核中',
    WITHDRAWAL_SAFE_PASSWORD: '提款密码',
    WALLET_BALANCE: '钱包余额',
    BANK_WITHDRAWAL_AMOUNT: '提现金额',
    BANK_WITHDRAWAL_CARD: '提现银行卡',
    BALANCE_MANAGEMENT: '资金管理',
    TODAY: '今天',
    YESTERDAY: '昨天',
    WITHIN7DAYS: '7天内',
    WITHIN30DAYS: '30天内',
    TRANSACTION_DETAILS: '交易详情',
    TRANSACTION_ID: '交易编号',
    USERNAME: '用户名',
    TRANSACTION_TYPE: '交易类型',
    TRANSACTION_TIME: '时间',
    TRANSACTION_AMOUNT: '交易金额',
    TRANSACTION_PROCESSING_FEE: '手续费',
    TRANSACTION_STATUS: '交易状态',
    TRANSACTION_DETAIL: '交易描述',
    TRANSACTION_REMARK: '审核备注',
    TRANSACTION_APPROVAL_CODE: '审核识别码',
    ZH: '整合',
    HC15: '1-5球',
    YZZH: '一字组合',
    EZZH: '二字组合',
    SZZH: '三字组合',
    EZDW: '二字定位',
    SZDW: '三字定位',
    EZHS: '二字和数',
    SZHS: '三字和数',
    ZX3: '组选三',
    ZX6: '组选六',
    FSZH: '复式组合',
    KD: '跨度',
    ZHLH: '组合龙虎',
    NNSH: '牛牛/梭哈',
    UNSETTLED_BET: '未结明细',
    BET_ID: '注单号',
    PLAYSTYLE: '玩法',
    BET: '下注',
    POTENTIAL_WINNING: '可赢',
    TOTAL_BET_COUNT: '注数',
    SETTLED_BET: '今日已结',
    WINNING_RESULT: '结果',
    WIN_LOSE: '输赢',
    BET_REPORT: '报表查询',
    UNTIL: '至',
    CHECK: '查询',
    DATE: '日期',
    REPORT_BET_AMOUNT: '下注金额',
    REPORT_REBATE_AMOUNT: '退水金额',
    PROFIT_LOSS: '盈亏',
    PLEASE_SELECT_DATE: '请选择日期',
    CANCEL: '取消',
    REDEEM_CODE: '兑换码',
    CONFIRM_REDEEM: '确认兑换',
    PLEASE_ENTER_REDEEM_CODE: '请输入兑换码',
    CODE_REDEMPTION: '优惠码兑换',
    VERSION: '版本',
    ALL_GAMES: '全部游戏',
    ALL_RECORDS: '全部记录',
    THIRD_PARTY_RECORDS: '第三方投注',
    PLEASE_SELECT: '请选择',
    SAFETY_CENTER: '安全中心',
    SAFETY_CENTER_HEADER_TEXT: '安全守护，无忧投注',
    SAFETY_CENTER_USERINFO: '个人资料',
    SAFETY_CENTER_USERINFO_SUBHEADER: '修改完善个人信息',
    SAFETY_CENTER_BINDPHONE: '手机号验证',
    SAFETY_CENTER_BINDPHONE_SUBHEADER: '绑定手机号，短信验证接收更便捷',
    SAFETY_CENTER_BINDCARD: '绑定银行卡',
    SAFETY_CENTER_CARD_LIST: '已绑定银行卡',
    SAFETY_CENTER_CARD_LIST_SUBHEADER: '查看绑定银行卡',
    SAFETY_CENTER_BINDCARD_SUBHEADER_FRONT: '最多可绑定',
    SAFETY_CENTER_BINDCARD_SUBHEADER_MIDDLE: '张，已绑定 ',
    SAFETY_CENTER_BINDCARD_SUBHEADER_BACK: '张',
    SAFETY_CENTER_BINDADDRESS: '我的收货地址',
    SAFETY_CENTER_BINDADDRESS_SUBHEADER: '优惠福利领取地址',
    SAFETY_CENTER_LOGIN_PASSWORD: '修改登入密码',
    SAFETY_CENTER_LOGIN_PASSWORD_SUBHEADER: '定期修改有利于账户安全',
    SAFETY_CENTER_WITHDRAW_PASSWORD: '提款密码',
    SAFETY_CENTER_WITHDRAW_PASSWORD_SUBHEADER: '设置独立密码',
    SAFETY_CENTER_UNBINDED: '未绑定',
    PERSONAL_INFORMATION: '个人信息',
    NAME: '姓名',
    DATEOFBIRTH: '生日',
    GENDER: '性别',
    LOTTERY_RULES_IMPORTANT_NOTICE: '重要声明',
    LOTTERY_RULES_RULES_EXPLANATION: '规则说明',
    LOTTERY_RULES_ALL_BETS_INCLUDE_PRINCIPLE: '以下所有投注皆含本金',
    LOTTERY_RULES_ALL_DETAILED_RULES: '具体游戏规则如下',
    BET_TIME: '下注时间',
    INSTALLMENTS_NUM: '期数',
    TYPE: '类型',
    BET_CONTENT: '下注内容',
    PAN_KOU: '盘口',
    REBATE: '退水',
    ODDS: '赔率',
    TOTAL: '总计',
    TIMES: '笔',
    DI: '第',
    HOME_PAGE: '首页',
    PREVIOUS_PAGE: '上一页',
    NEXT_PAGE: '下一页',
    LAST_PAGE: '最后一页',
    BET_LIMIT: '个人资讯',
    BET_LIMIT_MIN: '单注最低',
    BET_LIMIT_MAX: '单注最高',
    BET_LIMIT_WEEKLYMAX: '单期最高',
    PROMOTION: '优惠活动',
    PROMOTION_ALL: '全部',
    PROMOTION_DEPOSIT_WITHDRAWAL: '存提送',
    PROMOTION_LOTTERY_VOUCHER: '彩票卷',
    PROMOTION_OTHERS: '其他',
    BET_NUMBER: '笔数',
    DATE_RANGE: '日期范围',
    START_END_DATE: '开始日期  至  结束日期',
    THIS_WEEK: '本周',
    PREVIOUS_WEEK: '上一周',
    PREVIOUS_2_WEEK: '上两周',
    THIS_MONTH: '本月',
    PREVIOUS_MONTH: '上月',
    SAVE: '保存',
    RESET_DEFAULT: '恢复默认',
    USER_CENTER: '会员中心',
    PLEASE_CONFIRM_BET: '请确认详细注单',
    NUMBER: '号码',
    CONFIRM_BETS: '确认',
    CONFIRM_BET_TOTAL: '共',
    CONFIRM_BET_ITEM: '单',
    YUAN: '元',
    CONFIRM_BET_ACCOUNT_BALANCE: '余额',
    AGREE: '同意',
    DO_NOT_AGREE: '不同意',
    DELETE: '删除',
    RIGHT_NOW: '此刻',
    RECENT_1_WEEK: '最近一周',
    CURRENT_WEEK: '当前一周',
    RECENT_2_WEEK: '最近两周',
    JANUARY: '一月',
    FEBRUARY: '二月',
    MARCH: '三月',
    APRIL: '四月',
    MAY: '五月',
    JUNE: '六月',
    JULY: '七月',
    AUGUST: '八月',
    SEPTEMBER: '九月',
    OCTOBER: '十月',
    NOVEMBER: '十一月',
    DECEMBER: '十二月',
    START_DATE: '开始日期',
    END_DATE: '结束日期',
    MAX_DATE: '最大日期',
    APPLY: '确定',
    MONDAY: '周一',
    TUESDAY: '周二',
    WEDNESDAY: '周三',
    THURSDAY: '周四',
    FRIDAY: '周五',
    SATURDAY: '周六',
    SUNDAY: '周日',
    BET_INFO_BASIC_INFO: '基本信息',
    BET_INFO_ACCOUNT_BALANCE: '账号余额',
    BET_INFO_SUB_GAMEPLAY: '玩法',
    BET_INFO_BET_LIMIT: '单注最低',
    BET_INFO_BET_LIST: '单注最高',
    BET_INFO_INSTALLMENT_LIMIT: '单期最高',
    BET_INFO_MODAL_TITLE: '投注列表',
    BET_INFO_HEADER_DATE: '日期',
    BET_INFO_HEADER_AMOUNT: '金额',
    BET_INFO_HEADER_WITHDRAW: '提款',
    BET_INFO_HEADER_DEPOSIT: '存款',
    BET_INFO_HEADER_TRANSFER: '游戏转账',
    BET_INFO_HEADER_WIN_LOST: '输赢',
    BET_INFO_HEADER_WITHDRAW_CHARGES: '提款手续费',
    BET_INFO_HEADER_TOP_UP_CHARGES: '存款手续费',
    MORE_GAMES: '更多游戏',
    AUXYSCPK10: '幸运赛车',
    AUXYSSC: '幸运时时彩',
    HLSXSSC: '重庆欢乐生肖',
    SHORTCUT_SELECT_BET: '快捷填单',
    SHORTCUT_INPUT_PLACEHOLDER: '按号码=金额的格式，多个用空格分隔。如1=10 12=20',
    ODDS_TYPE: '类别',
    SAFETY_CENTER_SETTINGS: '设置',
    SAFETY_CENTER_BANKCARD: '银行卡',
    SAFETY_CENTER_COMPLETE_BUTTON: '完成',
    SAFETY_CENTER_UNBIND_BUTTON: '解绑手机',
    SAFETY_CENTER_BIND_BUTTON: '绑定手机',
    SAFETY_CENTER_BASIC_INFO: '基本资料',
    SAFETY_CENTER_FAMILY_NAME: '真实姓名',
    SAFETY_CENTER_FAMILY_NAME_PLACEHOLDER: '姓名需与银行卡持卡人姓名一致，否则无法提款',
    SAFETY_CENTER_FAMILY_NAME_EMPTY_ERROR: '请填写真实姓名',
    SAFETY_CENTER_GENDER_EMPTY_ERROR: '请填写性别',
    SAFETY_CENTER_DOB_ERROR: '请填写出生日期',
    SAFETY_CENTER_GENDER: '性别',
    SAFETY_CENTER_GENDER_MALE: '男',
    SAFETY_CENTER_GENDER_FEMALE: '女',
    SAFETY_CENTER_DATE_OF_BIRTH: '出生日期',
    SAFETY_CENTER_ACCOUNT_SAFETY: '账户安全',
    SAFETY_CENTER_SAFEPHONE: '手机号码',
    SAFETY_CENTER_SAFEPHONE_PLACEHOLDER: '信息在确认后将无法修改',
    SAFETY_CENTER_SAFEPHONE_ERROR: '请填写手机号码',
    SAFETY_CENTER_VALID_SAFEPHONE_ERROR: '请填写有效手机号码',
    SAFETY_CENTER_SAFEPHONE_VERICODE_ERROR: '请填写验证码',
    SAFETY_CENTER_SAFEPHONE_VERICODE: '获取验证码',
    SAFETY_CENTER_SAFEPHONE_VERICODE_PLACEHOLDER: '请输入验证码',
    SAFETY_CENTER_SAFEPASS: '原密码',
    SAFETY_CENTER_SAFEPASS_PLACEHOLDER: '请输入原密码',
    SAFETY_CENTER_OLD_SAFEPASS_EMPTY_ERROR: '请填写原密码',
    SAFETY_CENTER_NEW_SAFEPASS_EMPTY_ERROR: '请填写新密码',
    SAFETY_CENTER_CONFIRM_SAFEPASS_EMPTY_ERROR: '请填写确认密码',
    SAFETY_CENTER_SAFEPASS_MISMATCH_ERROR: '密码不一样',
    SAFETY_CENTER_SAFEPASS_COMBINATION_ERROR: '密码必须是 6 到 20 个字母数字',
    SAFETY_CENTER_FORGOT_PASSWORD: '忘记密码',
    SAFETY_CENTER_NEW_SAFEPASS: '新密码',
    SAFETY_CENTER_NEW_SAFEPASS_PLACEHOLDER: '请输入6-12字母+数字组合',
    SAFETY_CENTER_CONFIRM_NEW_SAFEPASS: '确认密码',
    SAFETY_CENTER_CONFIRM_NEW_SAFEPASS_PLACEHOLDER: '请再次输入密码',
    SAFETY_CENTER_GIFT_ADDRESS: '礼品地址',
    SAFETY_CENTER_GIFT_RECIPIENT: '收件人',
    SAFETY_CENTER_GIFT_RECIPIENT_PLACEHOLDER: '请输入收件人姓名',
    SAFETY_CENTER_GIFT_RECIPIENT_EMPTY_ERROR: '请填写收件人',
    SAFETY_CENTER_GIFT_PHONE_EMPTY_ERROR: '请填写手机号码',
    SAFETY_CENTER_GIFT_AREA_EMPTY_ERROR: '请填写省市区',
    SAFETY_CENTER_GIFT_DETAILED_ADDRESS_EMPTY_ERROR: '请填写详细地址',
    SAFETY_CENTER_GIFT_PHONE: '手机号码 (+86)',
    SAFETY_CENTER_GIFT_PHONE_PLACEHOLDER: '请输入手机号',
    SAFETY_CENTER_GIFT_AREA: '省市区',
    SAFETY_CENTER_GIFT_AREA_PLACEHOLDER: '请输入省市区',
    SAFETY_CENTER_GIFT_DETAILED_ADDRESS: '详细地址',
    SAFETY_CENTER_GIFT_DETAILED_ADDRESS_PLACEHOLDER: '请输入详细地址',
    HX: '合肖',
    LP: '连碰',
    DT: '胆拖',
    DTSZ: '胆拖数字',
    INPUT_DMSM: '请输入胆码数目',
    DT_TEXT: '请先填写胆码数目，再按所填数值选择胆码，之后选择拖码！',
    SAFETY_SETTINGS: '安全设置',
    CASHIER: '资金管理',
    TRANSFER_BALANCE: '额度转换',
    BET_RECORDS: '投注记录',
    TRANSACTIONS_RECORDS: '交易记录',
    ANNOUNCEMENT_CENTER: '消息中心',
    PAYMENT_TYPE: '支付方式',
    TOP_UP_TYPE: '充值类型',
    TOP_UP_METHOD: '充值方式',
    SELECT_WALLET: '选择钱包',
    CONFIRM_TOP_UP: '确定充值',
    TOP_UP_CATEGORY_WEB_BANK_PAY: '网银支付',
    TOP_UP_CATEGORY_ALIPAY_QR: '支付宝扫码',
    TOP_UP_CATEGORY_FAST_PAY: '快捷支付',
    TOP_UP_CATEGORY_CRYPTO_PAY: '虚拟币',
    TOP_UP_CATEGORY_BANK_CARD: '银行卡转卡',
    TOP_UP_CATEGORY_ALIPAY_TRANSFER: '支付宝转账',
    TOP_UP_CATEGORY_UNION_SHORTCUT: '银联快捷',
    TOP_UP_CATEGORY_CLOUD_PAY: '云闪付',
    TOP_UP_CATEGORY_BANK_CARD_TRANSFER: '银行卡汇款',
    TOP_UP_CATEGORY_WECHAT_PAY: '微信',
    TOP_UP_CATEGORY_QQ_PAY: 'QQ支付',
    TOP_UP_CATEGORY_OTHERS_PAY: '其他支付',
    TOP_UP_CATEGORY_OFFLINE_PAY: '线下支付',
    TOP_UP_NAME: '开户名',
    CRYPTO_TYPE: '虚拟币种类',
    TOP_UP_MIN: '单笔最少',
    TOP_UP_MAX: '最多',
    TOP_UP_TRANSACTION_FEES_DESC: '本次存款将收取 X% 手续费。',
    ORDER_ID_GENERATED: '订单生成',
    ORDER_ID: '订单号',
    WALLET_NAME: '钱包名称',
    WALLET_ADDRESS: '钱包地址',
    TRANSACTION_QUANTITY: '交易数量',
    TOP_UP_REMINDER: '成功付款后,将会自动到帐,并弹出到帐提示。如有疑问,请联系客服!',
    COMPLETE_TOP_UP: '完成充值',
    BANK_CARD_BANK: '开户行',
    BANK_CARD_ADDRESS: '开户行地址',
    TOP_UP_BANK_NUM: '开户账号',
    SCAN_ENDPOINT: '扫码终端',
    ACCOUNT_KEYCODE: '识别码',
    ATTENTION: '注意',
    TOP_UP_ATTENTION_TEXT: '转账备注必须正确填写识别码，否则影响上分。如因识别码无效造成上不了分，本公司则不负责。',
    COPY: '复制',
    TOP_UP_USER_REAL_NAME: '真实姓名',
    PLEASE_ENTER_REAL_NAME: '请填写真实姓名',
    PLEASE_ENTER_NAME: '请输入姓名',
    PLEASE_ENTER_MIN_AND_MAX_AMOUNT: '请输入X元~Y元',
    PLEASE_ENTER_VALID_AMOUNT: '请输入有效金额',
    CURRENCY_EXCHANGE: '参考汇率',
    TOP_UP_ISSUEING_BANK: '开户行',
    REMINDER: '提示',
    EXCHANGE_RATE: '汇率',
    PRESET_AMOUNT: '预计金额',
    ARE_YOU_SURE_YOUR_TOP_UP_HAS_SUCCEED: '您确认是否已经充值成功？',
    CANCEL_TOP_UP_ORDER: '取消订单',
    GAMES: '游戏',
    THIRD_PARTY_SETTLED: '已结',
    THIRD_PARTY_UNSETTLE: '未结',
    OTHERS: '其他',
    STATUS: '状态',
    TO: '至',
    SEARCH: '搜索',
    GAME_NAME: '游戏名称',
    TOTAL_BET_RECORDS_COUNT: '注单数',
    EFFECTIVE: '有效',
    CURRENT_PAGE: '本页',
    EFFECTIVE_AMOUNT: '有效金额',
    WIN_LOSE_RESULT: '输赢结果',
    TOTAL_COUNT: '总数',
    CONTENT: '内容',
    CENTER_WALLET: '中心钱包',
    LOCK_WALLET: '锁定钱包',
    AUTO_TRANSFER: '自动转账',
    AUTO_TRANSFER_TIPS: '开启后余额自动转入游戏场馆',
    ONE_STEP_RECYCLE: '一键回收',
    RECYCLE: '回收',
    TRANSFER_AMOUNT: '转账金额',
    INPUT_TRANSFER_AMOUNT: '请输入转账金额',
    INSTANT_TRANSFER: '立即转账',
    REFRESH: '刷新',
    DAN: '胆',
    CURRENT_PAGE_TOTAL: '此页面统计',
    BET_ITEM_DETAILS: '注单详情',
    BET_ITEM_CONTENT: '投注内容',
    BET_ITEM_COUNT: '下注数',
    WINNING_BET_ITEM_COUNT: '中奖注数',
    WINNING_AMOUNT: '奖金',
    WINNING_PER_BET: '单注可赢',
    BANKCARD_OVER_BANKCARD_LIMIT: '请解绑卡再试',
    BANKCARD_INVALID_FIELDS: '请修改后再试',
    BANKCARD_ADD_CARD_HEADER: '添加银行卡',
    BANKCARD_ADD_CARD_CARD_NAME: '持卡人名',
    BANKCARD_ADD_CARD_CARD_NAME_PLACEHOLDER: '请输入持卡人姓名',
    BANKCARD_ADD_CARD_CARD_NAME_TIPS: '为了您的资金能够迅速到账，请确保填写的姓名与银行卡的开户姓名一致',
    BANKCARD_ADD_CARD_CARD_BANK: '所属银行',
    BANKCARD_ADD_CARD_CARD_BANK_NUMBER: '银行卡号',
    BANKCARD_ADD_CARD_CARD_BANK_NUMBER_PLACEHOLDER: '请输入银行卡号',
    BANKCARD_ADD_CARD_CARD_BANK_NUMBER_TIPS: '请认真校对银行卡号，卡号错误资金将无法到账',
    BANKCARD_ADD_CARD_CARD_BANK_ADDRESS: '银行地址',
    BANKCARD_ADD_CARD_CARD_BANK_ADDRESS_PLACEHOLDER: '请输入开户行地址',
    BANKCARD_ADD_CARD_CARD_SAFEPASS: '提款密码',
    BANKCARD_ADD_CARD_CARD_SAFEPASS_PLACEHOLDER: '请输入提款密码',
    BANKCARD_ADD_CARD_COMPLETE: '完成',
    BANKCARD_CARD_LIST_HEADER: '银行卡',
    WALLET_DETAIL: '钱包明细',
    WITHDRAWAL_TYPE: '取款方式',
    SPEED_WITHDRAWAL: '极速取款',
    GENERAL_WITHDRAWAL: '普通取款',
    USDT_WITHDRAWAL: 'USDT 取款',
    WITHDRAWAL_AMOUNT: '提款金额',
    CONFIRM_WITHDRAWAL: '确定提款',
    INPUT_AMOUNT: '请输入金额',
    BANK_CARD: '银行卡',
    ADD_BANK_CARD: '添加银行卡',
    CONFIRM_WITHDRAWAL_WARNING: '您的款项一般会在10分钟内到账，如果超过30分钟还未到账，请联系我们的7*24小时在线客服',
    ALL_TYPES: '全部类型',
    ALL_STATUS: '全部状态',
    SERIAL_NUM: '序号',
    DIVIDEND: '红利',
    CASH_REBATE: '反水',
    PROMOTIONS: '活动优惠',
    MANUAL_TOP_UP: '人工加额',
    TRANSFER: '转账',
    UNLOCK_AMOUNT: '解锁金额',
    LOCK_AMOUNT: '金额锁定',
    FINANCIAL_TOP_UP: '财务加额',
    FINANCIAL_DEDUCT: '财务扣额',
    SUCCESS: '成功',
    FAIL: '失败',
    PENDING: '待处理',
    PENDING_APPROVAL: '待审核',
    CLOSE: '关闭',
    JSKL10: '极速快乐十分',
    RETURN: '返回',
    BET_REPORT_DETAIL: '注单明细',
    SUM: '总和',
    ARE_YOU_SURE_TO_DELETE_BANK_CARD: '您是否确认删除银行卡？',
    PLEASE_ENTER_SAFE_PASS: '请输入安全密码',
    UNBINDED_CARD_ERROR_MESSAGE: '未绑定银行卡',
    BANKCARD_NAME_ERROR: '请输入持卡人名',
    BANKCARD_BANK_ERROR: '请选择所属银行',
    BANKCARD_NUMBER_ERROR: '请输入银行卡号',
    BANKCARD_ADDRESS_ERROR: '请输入银行地址',
    BANKCARD_PASSWORD_ERROR: '请输入提款密码',
    NOTIFICATION: '通知',
    NEWS: '公告',
    FEEDBACK: '反馈',
    TITLE: '标题',
    TIME: '时间',
    UNREAD: '未读',
    READ: '已读',
    NOTIFICATION_DETAIL: '消息详情',
    SUGGESTION_FEEDBACK: '意见反馈',
    FINANICAL_PROBLEM: '财务问题',
    TECHNICAL_PROBLEM: '技术问题',
    BUSINESS_CONSULTATION: '业务咨询',
    SUGGESTIONS: '意见建议',
    SYSTEM_INFORMATION: '系统消息',
    OTHER_QUESTIONS: '其他问题',
    SUBMIT: '提交',
    PLEASE_DESCRIBE_YOUR_ISSUES: '请详细描述您遇到的问题',
    PLEASE_ENTER_TITLE: '请输入标题',
    PLEASE_ENTER_TYPE: '请输入类型',
    PLEASE_ENTER_CONTENT: '请输入内容',
    CHANGE_PASSWORD_OLD_PASSWORD: '原始密码',
    CHANGE_PASSWORD_NEW_PASSWORD: '新设密码',
    CHANGE_PASSWORD_CONFIRM_PASSWORD: '确认密码',
    CHANGE_PASSWORD_PASSWORD_PLACEHOLDER: '请输入原始密码',
    CHANGE_PASSWORD_NEW_PASSWORD_PLACEHOLDER: '6-20个字母、数字或组合组成，区分大小写',
    CHANGE_PASSWORD_CONFIRM_PASSWORD_PLACEHOLDER: '请再次输入密码以确保输入无误',
    CHANGE_PASSWORD_PASSWORD_EMPTY_ERROR: '请输入原始密码',
    CHANGE_PASSWORD_NEW_PASSWORD_EMPTY_ERROR: '请输入新设密码',
    CHANGE_PASSWORD_NEW_PASSWORD_UNFIT_ERROR: '6-20个字母、数字或组合组成，区分大小写',
    CHANGE_PASSWORD_CONFIRM_PASSWORD_EMPTY_ERROR: '请输入确认密码',
    CHANGE_PASSWORD_NEW_CONFIRM_PASSWORD_MISMATCHED_ERROR: '两次输入密码不一致',
    LOTTERY_RESULT_CANCEL: '官方停售，奖期取消',
    DXYFTPK10: '幸运飞艇',
    CXYFTPK10: '幸运飞艇',
    CQSSC: '重庆时时彩',
    COMPLETE: '完成',
    BIG_SMALL: '大小',
    ODD_EVEN: '单双',
    LAST_NUM_BIG_SMALL: '尾大小',
    COMBINE_ODD_EVEN: '合数单双',
    QUICK_BET_SELECTION: '快速选单',
    USER_AGREEMENT: '用户协议',
    IMPORTANT_NOTICE: '重要公告',
    DO_NOT_REMIND_TODAY: '今日不再提醒',
    CHECK_OUT_DETAIL: '查看全部',
    CHANGLONG_LIANGMIAN: '两面',
    CHANGLONG_TEMA: '特码',
    CHANGLONG_CLEAR: '清空',
    CHANGLONG_SELECT_ALL: '全选',
    CHANGLONG_FREQUENCY_SELECT: '连出期数',
    XYSSCFTSSC: '时时彩番摊',
    BJFTPK10: 'PK10番摊',
    CQFTSSC: '重庆番摊',
    XYFTFTPK10: '飞艇番摊',
    NO_DATA: '暂时无数据访问',
    PAGE: '页',
    X_AMOUNT_ODDS_CHANGE_ARE_YOU_SURE_TO_UPDATE: '您有X笔注单的赔率有更改，您是否更新？',
    UPDATE_IMMEDIATELY: '立即更新',
    CANCEL_IMMEDIATELY: '立即删除',
    AUXY20BJK8: '澳洲幸运20',
    BET_REPORT_TOTAL: '统计',
    GD11X5: '广东11选5',
    GX11X5: '广西11选5',
    JX11X5: '江西11选5',
    SD11X5: '山东11选5',
    DANHAO: '单号',
    LOTTERY_HAD_CLOSED: '现已关盘',
    NEXT_DRAW_TIME_IS: '下期开盘时间为',
    TWHK6: '台湾大乐透',
    PK10: '北京赛车',
    CQXYNC: '重庆幸运农场',
    VOUCHER_REDEMPTION: '优惠码兑换',
    VOUCHER_REDEMPTION_EMPTY_ERROR: '优惠码不能为空!',
    HE_DAN_SHUANG: '合单双',
    DA_XIAO_WEI: '大小尾',
    SE_BO: '色波',
    FU_LU_SHOU_XI: '福禄寿喜',
    PCDD: 'PC蛋蛋',
    GXK3: '广西快3',
    WITHDRAWAL_BANK_CARD_PLACEHOLDER: '请选择银行卡',
    CARD_ENDING: '尾数',
    ERROR_UNSELECTED_BANKCARD: '请选择提现银行卡',
    ERROR_EMPTY_AMOUNT: '请输入提现金额',
    ERROR_AMOUNT_MORE_THAN: '提现金额不能大于',
    HN5MSSC: '河内5分彩',
    BJK3: '北京快3',
    XY28KL8: '幸运28',
    SGFTPK10: 'SG飞艇',
    XYFTPK10: '皇家幸运飞艇',
    XYSCFTPK10: '赛车番摊',
    JSXYFTPK10: '极速飞艇',
    GDKL10: '广东快乐十分',
    BJK8: '北京快乐8',
    JJSSCPK10: '极速赛车',
    JJSSSC: '极速时时彩',
    XJSSC: '新疆时时彩',
    JAUXY5SSC: '英国幸运5',
    JAUXY8KL10: '英国幸运8',
    SNPHK6: '新加坡六合彩',
    CCHK6: 'CC六合彩',
    SNPFCHK6: '新加坡福彩',
    SNPDYHK6: '新加坡天天彩',
    JAUXYPK10: '英国幸运10',
    YHFSPK10: '竞速飞车',
    JXJ3SSC: '新疆3分彩',
    YHFSFTPK10: '竞速赛艇',
    YHFSSSC: '竞速分分彩',
    JXJ5SSC: '新疆5分彩',
    JKLFTPK10: '快乐飞艇5',
    YH3DK3: '竞速快三',
    TJSSC: '天津时时彩',
    YHKL10: '竞速快乐10分',
    HYJSSCPK10: '极速赛车',
    YHHK6: '竞速六合彩',
    HYJSSSC: '极速时时彩',
    IGSSC: 'IG时时彩',
    IGPK10: 'IG赛车',
    AUXY8KL10: '澳洲幸运8',
    CJ_11X5JSC: '极速11选5',
    JSNSSC: '极速时时彩',
    JSSCNPK10: '极速赛车',
    USASCPK10: '美国赛车',
    AUXYPK10: '澳洲幸运10',
    AUXY5SSC: '澳洲幸运5',
    WNSK3: '威尼斯快3',
    FJK3: '福建快3',
    JSUK3: '江苏快3',
    SHK3: '上海快3',
    JLK3: '吉林快3',
    JJSHK6: '极速六合彩',
    FJ36X7: '福建36选7',
    FJ31X7: '福建31选7',
    CURRENT_LOTTERY: '当前彩种',
    CUSTOMIZE: '自定义',
    DSNJSSCPK10: '极速赛车',
    CHANGLONG_RANK: '长龙排行',
    CAIZHONG: '彩种',
    GE: '个',
    _PK10_SERIES: 'PK10系列',
    _SSC_SERIES: '时时彩系列',
    _HK6_SERIES: '六合彩系列',
    _KL10_SERIES: '快乐十分系列',
    _11X5_SERIES: '11选5系列',
    _GXK10_SERIES: '广西快乐十分系列',
    _F36X7_SERIES: '福建36选7系列',
    _BJK8_SERIES: '北京快乐8系列',
    _PCDD_SERIES: 'PC蛋蛋系列',
    _F31X7_SERIES: '福建31选7系列',
    ACCOUNT_LOGGED_IN_ELSEWHERE_MESSAGE: '你的账号已在其他地方登入',
    EXCHANGE_RATE_FRONT: '对RMB，当前参考兑率为',
    EXCHANGE_RATE_BACK: '实际兑率请以到账兑率为准',
    TOPUP_NAME_PLACEHOLDER: '存款人真实姓名',
    FORECAST: '预计',
    OTHER_AMOUNT: '其他金额',
    OTHER_AMOUNT_PLACEHOLDER: '请输入存款金额',
    AMOUNT_ERROR: '金额必须大于0',
    NAME_ERROR: '存款人真实姓名不能为空',
    TOTAL_AMOUNT: '总金额',
    TOP_UP_REMINDER_WARM_TIPS: '温馨提示',
    QR_CODE: '二维码',
    MY_WALLET: '我的钱包',
    GAME_LOBBY: '游戏大厅',
    CO_OP: '加盟合作',
    FAQ: '常见问题',
    INBOX: '历史公告',
    CHANGE_THEME: '换肤',
    MOBILE_LOGOUT: '退出',
    TEST_PLAYER: '试玩用户',
    PROFILE_DRAWER_REGISTER: '立刻注册',
    WAIT_X_BEFORE_REQUEST: 'X秒后再请求',
    GAME_MENU: '游戏菜单',
    THIRD_PARTY_GAMES: '第三方游戏',
    TRANSFER_OUT: '转出',
    TRANSFER_IN: '转入',
    PLEASE_SELECT_TRANSFER_OUT_ACCOUNT: '请选择转出账号',
    PLEASE_SELECT_TRANSFER_IN_ACCOUNT: '请选择转入账号',
    ENTER_GAME: '进入游戏',
    BALANCE: '额度',
    UNSETTLED_AMOUNT: '未结算金额',
    CLOSE_PAN: '封盘',
    GYH: '冠亚和',
    LH: '龙虎',
    SAFE_PASS_SUBHEADER: '为了账户安全，请您定期修改提款码，密码由6-20位字母+数字组合',
    RESET_PASSWORD_SUBHEADING: '请输入新的密码，进行密码重置',
    CHANGE_PASSWORD_SUBHEADING: '为了账户安全，请您定期修改密码，密码由6-20位字母+数字组合',
    NEW_GIFT_ADDRESS: '新增地址',
    DETAIL: '详情',
    BIND_CARD_SUBHEADER: '为保证账户资金安全，提款人的姓名填写后不可变更，如需要变更请联系客服',
    BIND_PHONE_HEADER: '手机解绑验证',
    UNBIND_PHONE_HEADER: '手机绑定验证',
    BIND_PHONE_SUBHEADER: '* 为了您的账号资料与资金安全，请绑定正常用手机号！',
    YXXZH: '鱼虾蟹/总和',
    _14LH: '1-4龙虎',
    SECONDS_TO_RETRY: '秒再试',
    BANK_CARD_DETAIL: '银行卡详情',
    MOBILE_LOGOUT_REMINDER: '退出提示',
    QZHS: '前中后三',
    ZH_LH: '总和/龙虎',
    COOP_TITLE: '加盟合作',
    COOP_ADVANTAGE: '加盟优势',
    COOP_AGREEMENT: '代理协议',
    AUTO_TRANSFER_HEADER: '游戏免转',
    TRANSFER_ALL: '全部转入',
    GAME_WALLETS: '游戏钱包',
    GAME_WALLETS_RIGHT: '一键转账，一步到位',
    ONE_KEY_TRANSFER_TO: '一键转入',
    ON: '开',
    OFF: '关',
    TRANSFER_SAME_SELECTED_ERROR: '转出转入不能相同',
    MORE_OPTIONS: '更多操作',
    ALL_TRANSACTION: '全部',
    CANCEL_ORDER_TIP: '您确定要取消该订单？',
    TEDAXIAO: '特大小',
    TEHEWEI: '特合尾',
    ZH_FH_DX_DS: '总和，番号，单双，大小',
    CONNECTION_DETAIL: '网络讯息',
    UNABLE_TO_CONNECT: '无法连接',
    NOTICE_DETAIL: '公告详情',
    _PROMOTION: '优惠',
    HAVE_ACCOUNT: '已有账号',
    PLEASE_LOGIN: '请登入',
    BACK_PAGE: '返回页面',
    NO_ACCOUNT: '没有账号',
    PLEASE_REGISTER: '请注册',
    MEMBER_LOGIN: '信用会员登陆',
    LOW_POPULAR_ACCOUNT_BALANCE: '低频彩额度',
    HIGH_POPULAR_ACCOUNT_BALANCE: '高频彩额度',
    RANDOM_NUM: '随机挂码',
    RANDOM_NUM_SHORTEN: '挂码',
    EMAIL_LOGIN: '邮箱登录',
    CUSTMOER_FEEDBACK: '意见反馈',
    CLEAR: '清除',
    GENERATE: '生成',
    RESTORE: '复位',
    GENERATE_NUMBER_BOX: '生成号码框',
    GENERATE_NUMBER: '生成号码',
  }
};