import { useEffect, useState } from 'react';

import { Button, Col, Input, Row } from 'reactstrap';
import { IoTrashOutline } from 'react-icons/io5';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { appActions, cartActions, gameActions, placeBetActions } from '../../../redux/actions';
import Label from '../../Label';
import { validateNumber } from '../../../constants/utils';
import './styles.scss';

const mapStateToProps = state => ({
  platform: state.appReducers.platform,
  lotteryType: state.gameReducers.lotteryType,
  gameInfo: state.placeBetReducers.gameInfo,
  cart: state.cartReducers.cart,
  userInfo: state.memberReducers.userInfo,
  presetEnabled: state.gameReducers.presetEnabled,
  oddsType: state.appReducers.oddsType,
  userGames: state.homeReducers.userGames,
});

const mapDispatchToProps = dispatch => ({
  updateModalAlert: modalAlert => dispatch(appActions.updateModalAlert(modalAlert)),
  updateCart: cart => dispatch(cartActions.updateCart(cart)),
  confirmBets: (betItem, lotteryType, gameType, callback) => dispatch(placeBetActions.confirmBets(betItem, lotteryType, gameType, callback)),
  removeItemInCart: keyCode => dispatch(cartActions.removeItemInCart(keyCode)),
  updatePresetAmount: amount => dispatch(gameActions.updatePresetAmount(amount)),
});

function ConfirmBetModal({ userGames, oddsType, data, platform, userInfo, lotteryType, presetEnabled, updateModalAlert, gameInfo, cart, updateCart, confirmBets, removeItemInCart, updatePresetAmount }) {
  const { t } = useTranslation();
  const [_cart, setCart] = useState();
  const [_totalAmount, setTotalAmount] = useState(0);
  const [_potentialWinning, setPotentialWinning] = useState(0);
  const [_presetEnabled, setPresetEnabled] = useState(false);

  const [lotteryName, setLotteryName] = useState('');


  useEffect(() => {
    if (lotteryType) {
      if (userGames && userGames?.lotteryList) {
        const temp = userGames?.lotteryList.find(x => x.code === lotteryType.item)?.lotteryName ?? '';
        setLotteryName(temp);
      }
    }
  }, [lotteryType]);

  useEffect(() => {
    if (!gameInfo.oddsList || !cart || (cart && Object.keys(cart).length === 0)) {
      updateModalAlert({
        visible: false
      });
    }
    if (cart) {
      setCart(cart);
      generateTotalAmount(cart);
    }
  }, [gameInfo, cart]);

  useEffect(() => {
    if (presetEnabled !== _presetEnabled) {
      setPresetEnabled(presetEnabled);
    }
  }, [presetEnabled]);

  const updateCartByKey = (key, value) => {
    const tempCart = JSON.parse(JSON.stringify(_cart));
    tempCart[key].amount = value;
    setCart(tempCart);
    updateCart(tempCart);
    generateTotalAmount(tempCart);
  };

  const generateTotalAmount = tempCart => {
    let total = 0;
    let potentialWinning = 0;
    Object.entries(tempCart).forEach(([, item]) => {
      total += (item.amount === undefined ? 0 : Number(item.amount));
      potentialWinning += (item.amount - 1) * item.odds;
    });
    setTotalAmount(total);
    setPotentialWinning(potentialWinning > 0 ? potentialWinning.toFixed(1) : 0);
  };

  useEffect(() => {
    const keyDownHandler = event => {
      if (event.key === 'Enter') {
        onSubmitBetItem();
        event.preventDefault();
      }
    };
    document.addEventListener('keydown', keyDownHandler, false);
    return () => {
      document.removeEventListener('keydown', keyDownHandler, false);
    };
  }, [cart]);

  const onSubmitBetItem = () => {
    const betItems = Object.entries(cart).map(([, item]) => ({
      keyCode: item.keyCode,
      odds: item.odds,
      amount: item.amount,
      displayName: item.displayName,
      isTmb: item.isTmb,
    }));
    confirmBets(betItems, lotteryType.item, oddsType, data?.callbackFn);
  };

  return (
    <div className={`confirmBetWrapper${platform}`}>
      <Row className="modalHeader">
        {t('PLEASE_CONFIRM_BET')}
      </Row>
      <Row className="modalContent p-3">
        <Row className="lotteryWrapper mx-0">
          <div className="col-6 d-flex">
            {`${lotteryName} - ${gameInfo.installment}`}
          </div>
          {userInfo
          && (
          <div className="col-6 justify-content-end d-flex">
            {`${userInfo?.info?.accountOption === 0 ? userInfo?.limitType : userInfo.accountList.find(x => x.type === userInfo.accType)?.name}: ${userInfo ? userInfo.accountList.find(x => x.type === userInfo.accType)?.accountBalance : '--'}`}
          </div>
          )}
        </Row>
        <Row className="mt-3 lotteryListWrapper">
          <table>
            <thead>
              <td className="ballNumCol">{t('NUMBER')}</td>
              <td className="oddsCol">{t('ODDS')}</td>
              <td className="amountCol">{t('AMOUNT')}</td>
              <td className="confirmCol">{t('DELETE')}</td>
            </thead>
            <tbody>
              {
                _cart && Object.entries(_cart).map(([key, item]) => (
                  <tr key={`${item.midType}-${key}-${item.displayName}`}>
                    <td className="ballNumCol">{`${item.midType}--${item.displayName}`}</td>
                    <td className="oddsCol">{item.odds}</td>
                    <td className="amountCol"><Input value={item.amount || 0} onChange={({ target: { value: text } }) => updateCartByKey(key, validateNumber(text))} className="col-9" /></td>
                    <td className="deleteCol" onClick={() => removeItemInCart(key)}>
                      <IoTrashOutline className="trashIcon" />
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
        </Row>
        <Row className="totalWrapper mt-3">
          <Col xs="6">
            <Label value={t('CONFIRM_BET_TOTAL')} />
            <Label className="amountText" value={(_cart && Object.keys(_cart).length) || 0} />
            <Label value={`${t('CONFIRM_BET_ITEM')},`} />
            <Label className="ml-2" value={t('CONFIRM_BET_TOTAL')} />
            <Label className="amountText" value={_totalAmount || 0} />
            <Label value={t('YUAN')} />
          </Col>
          <Col xs="6" className="justify-content-center d-flex">
            <div className="winAmountWrapper">
              {' '}
              {`${t('WIN_AMOUNT')}: ${_potentialWinning}`}
            </div>
          </Col>
        </Row>
        {/* <Row className="winAmountWrapper">
          <Col xs="12">
            {`${t('WIN_AMOUNT')}: ${_potentialWinning}`}
          </Col>
        </Row> */}
      </Row>
      <Row className="modalFooter p-3">
        <Col xs="6" className="justify-content-center d-flex">
          <Button
            type="button"
            className="confirmBtn p-0 col-8"
            onClick={() => {
              onSubmitBetItem();
              if (!_presetEnabled) {
                updatePresetAmount('');
              }
            }}
          >
            {t('CONFIRM')}
          </Button>
        </Col>
        <Col xs="6" className="justify-content-center d-flex">
          <Button
            type="button"
            className="confirmBtn p-0 col-8"
            onClick={() => updateModalAlert({
              visible: false
            })}
          >
            {t('CANCEL')}
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmBetModal);